<div class="dialog" height="800px" width="100%">
    <div class="dialog_button" style="text-align: center;">
        <h1 mat-dialog-title>{{data.title}}</h1>
    </div>
    <div mat-dialog-content height="800px" width="100%" [formGroup]="miFormulario">
        <mat-form-field style="width: 100%;">
            <textarea
                placeholder="Comments" 
                matInput
                #comments
                formControlName="comments"
                cdkTextareaAutosize
                cdkAutosizeMinRows="2"
                cdkAutosizeMaxRows="5"
                maxlength="500"
                required>
            </textarea>
            <mat-hint align="end">{{comments.value.length}}/500</mat-hint>
        </mat-form-field>

    </div>
    <div mat-dialog-actions>
        <button mat-flat-button color="primary" (click)="confirmado('approved')" *ngIf="data.showAcceptButton">APPROVE</button>
        <button mat-flat-button color="primary" (click)="confirmado('not approved')" *ngIf="data.showAcceptButton">NOT APPROVE</button>
        <button mat-flat-button color="accent" (click)="cerrarDialogo()" *ngIf="data.showCloseButton">CANCEL</button>
    </div>
</div>